@import '../../shared/settings';

.btn-close {
    position: relative;
    box-sizing: border-box;

    margin: 0;
    border-radius: 50%;
    background-color: var(--border);
    padding: 0;
    width: 20px;
    height: 20px;
    cursor: pointer;

    &:hover {
        background-color: var(--dark-grey);
    }

    svg {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 8px;
        height: auto;
        fill: white;
    }
}
