@import '../../shared/settings';

.is-loading {
    display: flex;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    flex-direction: column;
    justify-content: center;
    background: white;
    text-align: center;

    h2 {
        .loading {
            margin: 0;
        }
    }
}
