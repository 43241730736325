@import '../../shared/settings';

.modal-image {
    .modal-content {
        height: 100vh;
        width: 100vw;

        // overflow-hidden border radius Safari fix
        mask-image: radial-gradient(black, black);
    }

    .images {
        height: 100%;

        .carousel-container {
            .carousel-top {
                .carousel {
                    border-radius: 10px;
                }
            }

            &.has-thumbnails {
                .carousel-top {
                    .carousel {
                        border-radius: 10px 10px 0 0;
                    }
                }
            }
        }
    }

    figure {
        background-size: contain;
    }
}
