@import '../../backoffice/settings';

.ReactModal__Body--open {
    overflow: hidden;

    // makes body not scrollable but keeps the scrollbar
    // this makes site not wobble on browsers that show scrollbars
    &.ReactModal__Body--fixed {
        position: fixed;
        overflow-y: scroll;
        width: 100%;
    }

    @include respond-to(mobile) {
        div[aria-hidden='true'] {
            display: none;
            min-width: initial;
        }
    }

    // remove all posibly set z-index
    // so no elements can posibly be rendered on top
    // of highest modal

    @include respond-to(not-mobile) {
        #app,
        #app *,
        #app *::before,
        #app *::after {
            &:not(.site-top) {
                z-index: unset;
            }
        }
        .ReactModalPortal,
        .ReactModal__Overlay {
            z-index: 4;
        }
    }
}

.ReactModal__Overlay {
    @include scrollbar; /* cannot hide scrollbar! for people without scrollwheel */
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(black, 0.6);
    overflow: auto;

    @include respond-to(mobile) {
        background: white;
    }
}

.ReactModal__Content {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 32px;
    min-height: calc(100% - 64px);
    transition-duration: $fast;
    transition-property: opacity, transform;
    pointer-events: none;

    &:focus {
        outline: none;
    }

    @include respond-to(mobile) {
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        align-items: flex-start;
        justify-content: flex-start;
    }
}

.ReactModal__Content,
.ReactModal__Overlay {
    transition: opacity $fast ease-in-out;
    opacity: 0;

    &--after-open {
        opacity: 1;
    }

    &--before-close {
        opacity: 0;
    }
}
