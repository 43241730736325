@import '../../shared/settings';

.notification {
    border-top: 0 !important;
    border-radius: 3px !important;
    box-shadow: none !important;
    padding: 10px 12px !important;
    color: white !important;

    height: auto !important;

    h4 {
        margin: 0 0 3px !important;
        line-height: normal;
        color: white !important;
    }

    .notification-message {
        line-height: 18px;
        font-size: 12px !important;
    }

    .notification-dismiss {
        top: 6px !important;
        right: 6px !important;
        transition: background-color $fast;
        background-color: rgba(black, 0.12) !important;
        background-image: url('../../../img/close-notification.svg');
        background-repeat: no-repeat;
        background-position: center center;
        background-size: 6px 6px;
        text-indent: 999999px !important;

        &:hover {
            transition: background-color $fast;
            background-color: rgba(black, 0.24) !important;
        }
    }

    &.notification-success {
        background-color: $success !important;
    }

    &.notification-warning {
        background-color: $caution !important;
    }

    &.notification-error {
        background-color: $danger !important;
    }

    &.notification-info {
        background-color: $brand !important;
    }
}
