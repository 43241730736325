@import '../../shared/settings';

.form-row {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-top: 16px;

    &:first-child {
        margin-top: unset;
    }

    small {
        color: inherit;
        font-size: 14px;
    }

    p {
        margin: 0 0 8px 0;
        font-size: 14px;
    }

    .searchbox {
        height: 48px;
    }
}

.form-row-error {
    label {
        color: var(--danger);
    }
    #{$all-text-inputs},
    select {
        border-color: var(--danger) !important;
        background-color: rgba(var(--danger-rgb), 0.08) !important;
        color: var(--danger) !important;
    }
}

.form-row-error-message {
    display: inline-flex;
    margin-top: 8px;
    line-height: 22px;
    color: var(--danger);
    font-size: 14px;

    svg {
        stroke: var(--danger);
        margin-right: 8px;
        width: 20px;
        min-width: 20px;
    }

    p:first-of-type {
        margin-top: 0;
    }
    p:last-of-type {
        margin-top: 0;
    }
}

.combined-form-fields {
    display: flex;
    white-space: nowrap;
    gap: 8px;
}

.combined-form-rows {
    display: flex;
    align-items: flex-end;
    gap: 16px;

    .form-row {
        flex-grow: 1;
    }

    input[type='number'] {
        width: 120px;
    }

    .combined-by {
        color: $light-grey;
        font-size: 14px;
        font-weight: var(--medium);
        line-height: 48px;
    }
}

h2 {
    & + .combined-form-rows {
        margin-top: -14px;
    }
}

p {
    margin: 8px 0 16px;
}

select {
    &.before-input,
    &.after-input {
        margin: 0;
    }
}

span {
    &.before-input,
    &.after-input {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        border: 1px solid var(--border);
        border-radius: var(--input-radius);
        padding: 13px 16px 14px;
        min-width: 30px;
        height: $input-height;
        color: $dark-grey;
        font-size: 16px;
        font-weight: var(--medium);
        box-sizing: border-box;
        background: white;

        &.small {
            min-width: unset;
            padding: 0 6px;
            height: $input-small-height;
            font-size: 14px;
        }
    }
}

#{$all-text-inputs},
select,
span {
    &.before-input,
    &.after-input {
        &.small {
            border-color: $light-grey;
        }
    }

    &.after-input {
        display: inline-block;

        &:focus {
            border-color: $brand;
        }
    }

    &.before-input + .after-input {
        margin-left: -1px;
    }
}

.select-row {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    margin-bottom: 6px;

    .react-select {
        position: relative;
        margin-right: 10px;
        width: 100%;

        &:last-child {
            margin-right: 0;
        }
    }
}
