.theme-area-beezy {
    --sys-color-light-surface: #FFF;
    --sys-color-light-on-surface: #434D56;

    --brand: black;
    --brand-offset: #2B2B2B;
    --brand-offset-rgb: 43, 43, 43;
    --site-top-surface: var(--sys-color-light-surface);
    --site-top-on-surface: var(--sys-color-light-on-surface);

    .site-header .logo svg {
        height: 54px;
    }
}

.theme-area-bloemisten {
    --site-top-surface: #3C3200;
}


.theme-area-groningen {
    --site-top-surface: #1c2c3b;

    .site-header .logo svg {
        height: 36px;
    }
}

.theme-area-assen {
    --site-top-surface: #1a171b;
}

.theme-area-denbosch {
    --site-top-surface: #341d31;
}

.theme-area-geleen {
    --site-top-surface: #0095da;
}

.theme-area-hilversum {
    --site-top-surface: #1c2e50;
}

.theme-area-steenwijkerland {
    --site-top-surface: #1b427a;
}

.theme-area-terneuzen {
    --site-top-surface: #002c5c;
}

.theme-area-zeeland {
    --site-top-surface: #00529c;
}

.theme-area-zwolle {
    --site-top-surface: #131f28;
}
