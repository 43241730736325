@import '../../shared/settings';

.zupr-tooltip-tether {
    z-index: 102;
}

.zupr-tooltip {
    display: inline-block;
    transform: translateY(10px);
    border-radius: 4px;
    background: rgba($dark, 0.85);
    padding: 6px 10px 7px;
    max-width: 300px;
    text-align: center;
    line-height: 16px;
    color: white;
    font-size: 13px;
    font-weight: var(--medium);
    transition-duration: $fast;
    transition-property: opacity transform;
    box-sizing: border-box;
    pointer-events: none !important;
    z-index: 101;

    &::after {
        @include triangle(10px, rgba($dark, 0.85), down);
        position: absolute;
        bottom: -5px;
        left: 50%;
        transform: translateX(-50%);
        content: ' ';
    }
}

/* This fires as soon as the element enters the dorm */
.tooltip-transition-enter,
.tooltip-transition-appear {
    &.zupr-tooltip {
        opacity: 0;
        transform: translateY(15px);
    }
}

/* This is where we can add the transition*/
.tooltip-transition-enter-active,
.tooltip-transition-appear-active {
    &.zupr-tooltip {
        opacity: 1;
        transform: translateY(10px);
    }
}

/* This fires as soon as the showList is false */
.tooltip-transition-exit {
    &.zupr-tooltip {
        opacity: 1;
        transform: translateY(10px);
    }
}

/* fires as element leaves the DOM*/
.tooltip-transition-exit-active {
    &.zupr-tooltip {
        opacity: 0;
        transform: translateY(15px);
    }
}
