.modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 10px 10px 0 0;
    padding: 24px 16px;
    color: white;

    h3 {
        margin: 0;
        padding: 0;
        line-height: 27px;
    }

    .btn {
        margin: -6px;
    }

    @include respond-to(mobile) {
        border-radius: 0;
        padding: 16px;

        h3 {
            font-size: var(--systextbodysmallsize);
            line-height: 22px;
        }

        .btn {
            margin: unset;
        }
    }
}

.header-info {
    background: var(--info);
}

.header-success {
    background: var(--success);
}

.header-danger {
    background: var(--danger);
}

.header-warning {
    background: var(--warning);
}
