@import '../../shared/settings';

@import './positioning';

@import './header';
@import './image';
@import './content';

.modal-content {
    border-radius: 10px;
    background: white;
    width: 100vw;
    max-width: var(--site-width);
    min-height: 100px;
    max-height: calc(100vh - 64px);
    display: flex;
    flex-direction: column;

    @include respond-to(mobile) {
        width: 100%;
        max-width: 100vw;
        max-height: 100vh;
    }

    .modal-is-form &  {
        max-width: 620px;
        
        @include respond-to(mobile) {
            max-width: 100vw;
        }
    }
}
